import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../images/logo-text.svg";
import { StyledButtonLink, SubTitle, Title } from "../elements";
import DemoVideo from "../../images/screenshots/demo.mp4";

const Container = styled.section`
  padding: 16px 32px;
`;

const TitleContainer = styled.div`
  text-align: left;

  @media (min-width: 500px) {
    text-align: center;
  }
`;

const VideoContainer = styled.div`
  margin: 16px auto;
  text-align: center;
  margin-bottom: 0;
  line-height: 0;
  max-width: 100%;
  video {
    margin: 0;
    border-radius: var(--border-radius);
    background-color: #2b2b2b;
    padding: 8px;
    max-height: 400px;
  }
`;

const Hero = () => (
  <>
    <Container>
      <Logo className="logo" alt="CoLeague logo" />
      <TitleContainer>
        <Title>Premium leaderboards for the unexceptionally gifted.</Title>
        <SubTitle>Track and rate your office games.</SubTitle>
      </TitleContainer>
      <StyledButtonLink to="#contact">Get early access</StyledButtonLink>
      <VideoContainer
        dangerouslySetInnerHTML={{
          __html: `
    <video playsinline autoPlay loop muted autobuffer>
      <source src=${DemoVideo} type='video/mp4; codecs="avc1.42E01E"' />
</video>
`,
        }}
      />
    </Container>
  </>
);

export default Hero;
