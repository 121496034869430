import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useAsyncFn } from "react-use";

import { StyledButton, TextInput } from "../elements";
const Container = styled.div`
  margin: 16px auto;
`;
const FormGroup = styled.div`
  margin: 4px auto;
`;

const Contact = () => {
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");
  };
  const { register, handleSubmit, errors, formState } = useForm();
  const [state, submitData] = useAsyncFn(
    async (data) =>
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode(data),
      }),
    []
  );

  const onSubmit = (data, e) => {
    e.preventDefault();
    submitData(data);
  };

  return (
    <>
      <Container id="contact">
        <form
          onSubmit={handleSubmit(onSubmit)}
          name="contact"
          method="POST"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <input
            type="hidden"
            name="form-name"
            ref={register()}
            value="contact"
          />
          <input type="hidden" name="bot-field" />
          {state.loading ? (
            <div>Sending..</div>
          ) : state.error ? (
            <div>Error: {state.error.message}</div>
          ) : state.value && state.value.status === 200 ? (
            <>
              <div>Thanks, we&#39;ll be in touch soon</div>
            </>
          ) : (
            <>
              <FormGroup>
                <TextInput
                  name="email"
                  placeholder="Your Email"
                  errors={errors.email}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  })}
                />
              </FormGroup>
              {errors.email ? <p>{errors.email.message}</p> : null}
              <StyledButton type="submit" disabled={formState.isSubmitting}>
                I&#39;m interested!
              </StyledButton>
            </>
          )}
        </form>
      </Container>
    </>
  );
};
export default Contact;
