import React from "react";
import styled from "styled-components";
import { SubTitle, Title } from "../elements";

const Container = styled.div`
  padding: 0 32px;
  margin: 0 auto;
  max-width: 768px;
`;

const Paragraph = styled.div`
  margin-bottom: 16px;
  padding: 0;

  p {
    padding: 8px 0;
    @media (min-width: 768px) {
      font-size: 125%;
    }
    text-align: left;
    span {
      color: var(--primary);
      font-weight: 700;
    }
  }
`;

const About = () => (
  <>
    <Container>
      <Title>Story time</Title>
      <Paragraph>
        <p>
          Hey, welcome to what happens when you take FIFA at the office waaay
          too seriously.
        </p>
        <p>
          We like to pretend playing games in the office is all good fun, but
          deep down you know the competition is for real. At the end of the day,
          you want that sweet sweet nectar of earning the bragging rights.
        </p>
        <p>
          But playing games without tracking the results means you can&#39;t
          really tell who&#39;s better. So you start googling the sh*t out of
          ELO ratings and fire up an Excel sheet. You spend hours entering
          formulas into a table and keeping track of people putting the wrong
          score in the wrong place. There is a better way, and it&#39;s (almost)
          here.
        </p>
        <p>
          Introducing <span>CoLeague</span>: a simple, beautiful leaderboard for
          tracking and rating all your games you love. And with detailed stats
          and the PureSkill rating algorithm, you can finally put an end to the
          biggest question of life:
        </p>
      </Paragraph>
      <SubTitle>
        Who has the strongest will, the peak talent, and the purest skill to
        become the office champion?
      </SubTitle>
    </Container>
  </>
);

export default About;
