import React from "react";
import Layout from "../components/layout";
import Hero from "../components/index/hero";
import Features from "../components/index/features";
import About from "../components/index/about";
import Contact from "../components/index/contact";
import Footer from "../components/footer";
import SEO from "../components/seo";

const Index = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Features />
    <About />
    <Contact />
    <Footer />
  </Layout>
);

export default Index;
