import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { BadgeSpan, StyledButtonLink } from "../elements";

const Container = styled.section`
  padding: 16px 32px;
  .contactButton {
    margin-top: 16px;
  }
`;

const Screenshots = styled.div`
  margin: 16px auto;

  .gatsby-image-wrapper {
    padding: 8px 0;
    border-radius: var(--border-radius);
    max-height: 256px;
  }

  @media (min-width: 10px) {
    display: flex;
    justify-content: center;
    .gatsby-image-wrapper {
      width: 50%;
      max-width: 320px;
      max-height: 384px;
    }
  }
`;

const SliderNavigation = styled.div`
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
`;

const SliderNavigationItem = styled.div`
  border-radius: var(--border-radius);
  padding: 24px;
  background-color: var(--anti-flash-white);

  flex: 0 0 256px;
  min-height: 128px;
  width: 256px;
  margin: 0 16px;
  &:nth-child(n + 1) {
    margin-left: 0;
  }
  position: relative;
  overflow: hidden;
  text-align: left;
`;

const SliderNavigationItemTitle = styled.div`
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 8px;
`;

const SliderNavigationItemContent = styled.div`
  color: var(--black);

  white-space: normal;
  font-size: 14px;
  line-height: 1.2;
`;
const Center = styled.div`
  margin: 0 auto;
`;
const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      screenshotPlayerStats: file(
        relativePath: { eq: "screenshots/player-stats.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshotAddGame: file(
        relativePath: { eq: "screenshots/app-home.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container>
        <Center>
          <SliderNavigation>
            <SliderNavigationItem>
              <SliderNavigationItemTitle>
                Track your games
              </SliderNavigationItemTitle>
              <SliderNavigationItemContent>
                CoLeague let&#39;s you keep track of all your games with up to 8
                players with support for competitive versus games such as FIFA,
                foosball and table tennis (1v1, 2v2, 4v4 and everything in
                between) and ranking games such as Mario Kart and Smash Bros (1
                vs all).
              </SliderNavigationItemContent>
            </SliderNavigationItem>

            <SliderNavigationItem>
              <SliderNavigationItemTitle>
                PureSkill algorithm
              </SliderNavigationItemTitle>
              <SliderNavigationItemContent>
                With the PureSkill algorithm, CoLeague can track and rank the
                skill of all players in every game. It&#39;s ELO ratings on
                steroids to support a broader set of games and more accurately
                reflect the true skill of a player.
              </SliderNavigationItemContent>
            </SliderNavigationItem>
            <SliderNavigationItem>
              <SliderNavigationItemTitle>
                Beautiful player stats
              </SliderNavigationItemTitle>
              <SliderNavigationItemContent>
                Throw away those Excel sheets and your hacky ELO formulas, and
                enjoy a beautiful overview of the skill of each player which
                evolves over time.
              </SliderNavigationItemContent>
            </SliderNavigationItem>

            <SliderNavigationItem>
              <SliderNavigationItemTitle>
                Season management
                <BadgeSpan>Pro</BadgeSpan>
              </SliderNavigationItemTitle>
              <SliderNavigationItemContent>
                Start new seasons to give new and existing players a fresh
                chance to climb the ladder to the top.
              </SliderNavigationItemContent>
            </SliderNavigationItem>
          </SliderNavigation>
        </Center>
        <Screenshots>
          <Img
            fluid={data.screenshotAddGame.childImageSharp.fluid}
            imgStyle={{
              objectFit: "contain",
              objectPosition: "50% 50%",
            }}
          />
          <Img
            fluid={data.screenshotPlayerStats.childImageSharp.fluid}
            imgStyle={{
              objectFit: "contain",
              objectPosition: "50% 50%",
            }}
          />
        </Screenshots>
        <StyledButtonLink className="contactButton" to="#contact">
          Get early access
        </StyledButtonLink>
      </Container>
    </>
  );
};

export default Features;
